import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ContactInformation from '../container/ContactInformation/ContactInformation';
import GoogleMap from '../container/Map/GoogleMap';
import ContactFromContainer from '../container/ContactFromContainer/ContactFromContainer';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import ContactInformationTwo from '../container/ContactInformation/ContactInformationTwo.js';
import PrivacySection from '../components/Privacy/PrivacySection.jsx';

const Privacy = () => {
    return (
        <React.Fragment>
            <SEO title="MyFSConnect || Privacy" />
            {/* <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg-five.jpg"
                title="Privacy"
                content="Home"
                contentTwo="Privacy"
            /> */}
            <PrivacySection/>
            {/* <ContactInformationTwo/> */}
            {/* <ContactInformation />
            <ContactFromContainer /> */}
            {/* <GoogleMap /> */}
            {/* <Footer />
            <ScrollToTop /> */}
        </React.Fragment>
    )
}

export default Privacy;

