import React from 'react'
import './_legal.scss'

const LegalSection = () => {
  return (
    <>
    <div className="google-map-area section text-center section-padding-bottom">
            <div className="container">
            <p className='privacy-text'>
            <h2>Legal</h2>
            Unless otherwise indicated, information contained in this site is Copyright © 2023 JL13 Concepts Inc. (All Rights Reserved).
        <br/>
        <h4>Copyright and Intellectual Property Notice</h4>
        This website, along with all its components (including text, graphics, logos, audio, video, software, and other materials), is the exclusive property of JL13 Concepts Inc., © 2023. All international rights are reserved. Unauthorized reproduction, distribution, alteration, public display, or commercial exploitation of any content without explicit written permission from JL13 Concepts Inc. is strictly prohibited and may lead to legal action. This notice does not grant any license or right to use any trademarks, patents, trade secrets, or other intellectual property rights of JL13 Concepts Inc. or any third party.
        <br/>
        <h4>User Agreement and Acceptance of Terms</h4>
        By accessing or utilizing this website, MyFSConnect Products, or any related services, you affirm that you have read, understood, and agreed to comply with these terms and conditions and any future modifications. These modifications may be made at any time, with or without notice, and your continued use of the website or products following such changes constitutes your acceptance of the revised terms.
        <br/>
        <h4>Content Usage and Restrictions</h4>
        The content provided on this website and through MyFSConnect Products is intended for informational, personal, and non-commercial use. You agree not to copy, modify, create derivative works, publicly display, perform, distribute, or otherwise use the content in any way that violates these terms or any applicable laws or regulations. Additionally, any reverse engineering, decompiling, or disassembling of software or other products provided on this site is expressly prohibited unless such activity is expressly permitted by applicable law, notwithstanding this limitation.
        <br/>
        <h4>Submissions and User-Generated Content</h4>
        You agree that any material, information, or ideas that you transmit to or post on this website by any means will be treated as non-confidential and non-proprietary. By submitting or posting any material, you grant JL13 Concepts Inc. a perpetual, irrevocable, worldwide, royalty-free license to use, reproduce, display, perform, modify, transmit, and distribute those materials or information, and you also agree that JL13 Concepts Inc. is free to use any ideas, concepts, know-how, or techniques that you send us for any purpose.
        <br/>
        <h4>Third-Party Products and Services</h4>
        This website may mention or provide links to third-party products or services for your convenience. JL13 Concepts Inc. does not endorse, warrant, or assume responsibility for the accuracy, effectiveness, or reliability of such products or services. Any transactions or interactions with third-party providers are solely between you and the third party.
        <br/>
        <h4>Modification of Information</h4>
        JL13 Concepts Inc. reserves the right to make changes or updates to the information, services, products, and other materials on this website at any time without prior notice. Users are encouraged to review these terms regularly for updates or changes. Your continued use of the website after any changes indicates your acceptance of the new terms.
        <br/>
        <h4>Compliance with Local and International Laws</h4>
        Users of this website and MyFSConnect Products are solely responsible for compliance with all laws, rules, and regulations applicable to their use of the website and products, including laws regarding data privacy, international communications, and exportation of technical or personal data. It is prohibited to use or access the website or products from territories where their contents are illegal.
        <br/>
        <h4>Warranty Disclaimer</h4>
        JL13 Concepts Inc. provides this website and its content on an “as is” and “as available” basis. JL13 Concepts Inc. makes no representations or warranties of any kind, express or implied, as to the operation of this website, the accuracy, completeness, reliability, or availability of the website content, or that the website and its servers are free of viruses or other harmful components.
        <br/>
        <h4>Limitation of Liability</h4>
        In no event will JL13 Concepts Inc., its affiliates, officers, directors, employees, agents, suppliers, or licensors be liable for any direct, indirect, punitive, incidental, special, consequential, or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data, or other intangible losses, resulting from the use of, or the inability to use, the website and its content.
        <br/>
        <h4>Software Use and Licensing</h4>
        Software made available for download from this website is subject to the terms of the end-user license agreement (if any) that accompanies or is included with the software. You may not install or use any software that is accompanied by or includes a license agreement unless you first agree to the terms of the license agreement.
        <br/>
        {/* <h4>HOW WE SHARE YOUR INFORMATION</h4>
        We will share your information with third parties only in the ways that are described in this privacy statement. We may disclose User Provided and Automatically Collected Information:<br/>
        <ul>
            <li>As required by law, such as to comply with a subpoena, or similar legal process.</li>
            <li>When we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</li>
            <li>With our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
            <li>If MyFSConnect is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of this information, as well as any choices you may have regarding this information;</li>
        </ul>
        We will not rent or sell your personally identifiable information to others. In some cases, we may store personal information in locations outside the direct control of MyFSConnect (for instance, on servers or databases in managed cloud environments.)
        <br/> */}
        <h4>Trademark Information</h4>
        All trademarks, service marks, and trade names displayed on this website are proprietary to JL13 Concepts Inc. or other respective owners that have granted JL13 Concepts Inc. the right and license to use such marks. Unauthorized use of any trademarks, service marks, or logos may be a violation of federal and state trademark laws.
        <br/>
        <h4>Government Use of Software</h4>
        For users who are acquiring the software on behalf of any unit or agency of the U.S. Government, the software and related documentation are deemed to be &quot;commercial computer software&quot; and &quot;commercial computer software documentation&quot; respectively, pursuant to DFAR Section 227.7202 and FAR Section 12.212, as applicable.
        <br/>
        <h4>Export Control Compliance</h4>
        You acknowledge that the software and any related technical data and services are subject to the export control laws and regulations of the United States. You agree not to export, re-export, or transfer, directly or indirectly, any U.S. technical data acquired from JL13 Concepts Inc. or any products utilizing such data in violation of the United States export laws or regulations.
        <br/>
        <h4>Governing Law and Jurisdiction</h4>
        These terms and your use of the website are governed by and construed in accordance with the laws of Canada, without regard to its conflict of law principles. You agree to submit to the personal and exclusive jurisdiction of the courts located within Canada for any disputes arising from or related to your use of the website.
        <br/>
        UNLESS OTHERWISE INDICATED, INFORMATION CONTAINED IN THIS SITE IS
        COPYRIGHT © 2023 JL13 Concepts. ALL RIGHTS RESERVED.
            </p>
            </div>
        </div>
    </>
  )
}

export default LegalSection