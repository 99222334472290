import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";


const Logo = () => {
    return(
        <div className="header-logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img className="dark-logo" src="https://res.cloudinary.com/shopsfit/image/upload/v1699451207/fsconnect_logo_ekp4kb.png" alt="MyFSConnect Logo" />
            </Link>
        </div>
    )
}

Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;
