import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import LegalSection from '../components/Legal/Legal.jsx';

const Legal = () => {
    return (
        <React.Fragment>
            <SEO title="MyFSConnect || Legal" />
            {/* <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg-five.jpg"
                title="Legal"
                content="Home"
                contentTwo="Legal"
            /> */}
            <LegalSection/>
            {/* <Footer />
            <ScrollToTop /> */}
        </React.Fragment>
    )
}

export default Legal;