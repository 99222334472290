import React from 'react';
import {Link, NavLink} from 'react-router-dom';
// import {
//     getClosest,
//     getSiblings,
//     slideToggle,
//     slideUp,
// } from "../../../utils";
import { Dropdown } from 'react-bootstrap';

const MobileNavMenu = () => {
    // const onClickHandler = (e) => {
    //     const target = e.currentTarget;
    //     const parentEl = target.parentElement;
    //     if (
    //         parentEl?.classList.contains("menu-toggle") ||
    //         target.classList.contains("menu-toggle")
    //     ) {
    //         const element = target.classList.contains("icon")
    //             ? parentEl
    //             : target;
    //         const parent = getClosest(element, "li");
    //         const childNodes = parent.childNodes;
    //         const parentSiblings = getSiblings(parent);
    //         parentSiblings.forEach((sibling) => {
    //             const sibChildNodes = sibling.childNodes;
    //             sibChildNodes.forEach((child) => {
    //                 if (child.nodeName === "UL") {
    //                     slideUp(child, 1000);
    //                 }
    //             });
    //         });
    //         childNodes.forEach((child) => {
    //             if (child.nodeName === "UL") {
    //                 slideToggle(child, 1000);
    //             }
    //         });
    //     }
    // };
    return (
        <nav className="site-mobile-menu">
            <ul>
            <li>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">Home</span></NavLink>
                </li>
                <li>
                    <a href='/#feature'><span className="menu-text">Features</span></a>
                </li>
                <li>
                <a href='/#why-choose-us'><span className="menu-text"></span><span className="menu-text">Why Choose Us</span></a>
                </li>
                <li>
                <NavLink to={process.env.PUBLIC_URL + "/use-cases"}><span className="menu-text">Use Cases</span></NavLink>
                </li>
                <li>
                <a href='/#faq'><span className="menu-text">FAQs</span></a>
                </li>
                {/* <li>
                <NavLink to={process.env.PUBLIC_URL + "/recommended-devices"}><span className="menu-text">Recommended Devices</span></NavLink>
                </li> */}
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">Contact Us</span></NavLink>
                </li>
                <li>
                <Link to={process.env.PUBLIC_URL + "/book-a-demo"}>
                    <button className="btn btn-primary btn-hover-secondary">Book a Free Trial</button>
                    </Link>
                </li>
                <li className="btn btn-primary btn-hover-secondary">
                    <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                Portal
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                <Dropdown.Item href="https://us-portal.myfsconnect.com/portal/" target="_blank">US Portal</Dropdown.Item>
                                <Dropdown.Item href="https://eu-portal.myfsconnect.com/portal/" target="_blank">EU Portal</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                </li>
            </ul>
        </nav>
    )
}

export default MobileNavMenu;
