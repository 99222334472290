import React, {Fragment} from 'react';
import { useState } from 'react';
import { useForm } from "react-hook-form";

const ProjectForm = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        mode: "onBlur"
    });
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const onSubmit = async (data) => {
        try {
            const response = await fetch('https://myareaa.eastus.cloudapp.azure.com/v1/notification/fsconnect', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    first_name: data.firstname,
                    last_name: data.lastname,
                    email: data.email,
                    subject: 'BOOK A DEMO SUBMISSION',
                    organization: data.organization,
                    role: data.role,
                    message: data.message,
                    to: "sales@myfsconnect.com"
                }),
            });

            console.log("HERE: ",data)

            if (response.status === 200 || response.status === 201) {
                setSubmissionStatus('success');
                reset();
            } else {
                setSubmissionStatus('failed');
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
            setSubmissionStatus('failed');
        }
    };
    
    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-n6">
                    <div className="col-md-6 col-12 mb-6">
                    <input 
                            type="text" 
                            placeholder="Your First Name *" 
                            name="firstname" 
                            {...register("firstname", {
                                required: "First Name is required",
                            })} 
                        />
                        {errors?.name && <p>{errors.name?.message}</p>}
                    </div>
                    <div className="col-md-6 col-12 mb-6">
                    <input 
                            type="text" 
                            placeholder="Your Last Name *" 
                            name="lastname" 
                            {...register("lastname", {
                                required: "Last Name is required",
                            })} 
                        />
                        {errors?.name && <p>{errors.name?.message}</p>}
                    </div>
                    <div className="col-md-12 col-12 mb-6">
                        <input 
                            type="email" 
                            placeholder="Email *" 
                            name="email" 
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address",
                                },
                            })} 
                        />
                        {errors?.email && <p>{errors.email?.message}</p>}
                    </div>
                    <div className="col-md-12 col-12 mb-6">
                        <input 
                            type="text" 
                            placeholder="Organization *" 
                            name="subject" 
                            {...register("subject", {
                                required: "Organization is required",
                            })}
                        />
                        {errors?.subject && <p>{errors.subject?.message}</p>}
                    </div>
                    <div className="col-12 mb-6">
                        <textarea 
                            name="message" 
                            placeholder="Message" 
                            {...register("message", {
                                required: "Message is required",
                            })}
                        ></textarea>
                        {errors?.message && <p>{errors.message?.message}</p>}
                    </div>
                    <div className="col-12 text-center mb-6">
                        <button type="submit" className="btn btn-primary btn-hover-secondary">Submit</button>
                    </div>
                </div>
                {/* Submission status message */}
                {submissionStatus === 'success' && (
                    <p className="submission-success">Form submitted successfully!</p>
                )}
                {submissionStatus === 'failed' && (
                    <p className="submission-failed">Form submission failed. Please try again.</p>
                )}
            </form>
            <p className="form-messege"></p>
        </Fragment>
    )
}

export default ProjectForm;
