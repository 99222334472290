import React from 'react'
import './_privacy.scss'

const PrivacySection = () => {
    return (
        <div className="google-map-area section text-center section-padding-bottom">
            <div className="container">
            <p className='privacy-text'>
            <h2>Privacy Policy</h2>
        This privacy policy governs your use of MyFSConnect a software application (Application) on a mobile device and service that was created by
        JL13 Concepts. The Application includes Push to Talk (PTT) Voice, Group Text Messaging and Registered User Location Sharing.
        <br/>
        <h3>PERSONAL DATA WE COLLECT</h3>
        <h4>USER PROVIDED INFORMATION</h4>
        The Application obtains the information you provide when you download and register the Application. Registration with us is mandatory in order to be able to use the basic features of the Application. When you register with us and use the Application, you generally provide: your name, email address, user name, phone number and other registration information. We may also use the information you provided us to contact your from time to time to provide you with important information or required notices. We will not use this information for marketing promotions.
        <br/>
        <h4>AUTOMATICALLY COLLECTED INFORMATION</h4>
        <h4>DEVICE INFORMATION</h4>
        The Application may collect certain Device Information automatically, such as the type of mobile device you use, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information to assist in registration and use of the Application.
        <br/>
        <h4>USER GENERATED INFORMATION</h4>
        In addition to Device Information, the Application and service will automatically collect User Generated Data such as Call Logs, Messages and Location History.
        <br/>
        <h4>MICROPHONE (required)</h4>
        Access to the microphone is required in order to record your voice for PTT calls. This information is not shared outside your organization.
        <br/>
        <h4>CLIENT SIDE CALL RECORDING (optional)</h4>
        If enabled by your administrator, Client Side Recording will store audio for calls on all participating devices. This recorded audio is available on devices for up to 24 hours after the call before automatically being deleted. Customers choosing to enable client side recording should notify all users about the feature and comply with all State and Federal laws.
        <br/>
        <h4>PHONE CALL STATUS (required)</h4>
        Access to the ongoing status of phone calls on your device is required to arbitrate between voice and PTT calls. This information is not shared off your device nor is it ever stored.
        <br/>
        <h4>PHONE NUMBER (optional)</h4>
        Your phone number may optionally be provided and will be used to activate your account via SMS, share with your contacts so they can phone call you, and/or shared with customer support so they can contact you regarding your support request. This information is not shared outside your organization except as required by law. This information is kept as long as you keep it in the system until you remove it or deactivate your account.
        <br/>
        <h4>EMAIL ADDRESS (optional)</h4>
        Your may optionally be provided and will be used to activate your account via email, share with your contacts so they can email you, and/or shared with customer support so they can contact you regarding your support request. This information is not shared outside your organization except as required by law. This information is kept as long as you keep it in the system until you remove it or deactivate your account.
        <br/>
        <h4>DEVICE IMEI (optional)</h4>
        Your device IMEI (serial number) may optionally be used to activate your account. If allowed, this information will be provided to customer support as part of a support request you initiate. This information is not shared outside your organization except as required by law.
        <br/>
        <h4>LOCATION INFORMATION (optional)</h4>
        If you enable the location features, your location will be peridoically reported so that your contacts can see where you are on the map. This includes when the app is in the background. During PTT calls your location will be reported in real-time. This information is not shared outside your organization except as required by law. If enabled by your organization your periodic location data may be stored for up to 180 days and is then purged. Real-time location data is never stored.
        <br/>
        <h4>CAMERA / PHOTOS (optional)</h4>
        If you use the messaging feature, you may optionally allow access to the camera and/or photo gallery for use in sending image messages. This information is not shared outside your organization except as required by law. Your messages data is stored for 30 days and then purged.
        <br/>
        <h4>HOW WE SHARE YOUR INFORMATION</h4>
        We will share your information with third parties only in the ways that are described in this privacy statement. We may disclose User Provided and Automatically Collected Information:<br/>
        <ul>
            <li>As required by law, such as to comply with a subpoena, or similar legal process.</li>
            <li>When we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</li>
            <li>With our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
            <li>If MyFSConnect is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of this information, as well as any choices you may have regarding this information;</li>
        </ul>
        We will not rent or sell your personally identifiable information to others. In some cases, we may store personal information in locations outside the direct control of MyFSConnect (for instance, on servers or databases in managed cloud environments.)
        <br/>
        <h4>OPT-OUT</h4>
        You may opt out of all information collection by uninstalling the Application. You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.
        <br/>
        <h4>DATA SECURITY</h4>
        We follow generally accepted industry standards to protect the Personal Data provided to us, both during transmission and once we receive it. We maintain appropriate administrative, technical and physical safeguards to protect information we collect against accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse, and any other unlawful form of processing of the Personal Data in our possession. This includes, for example, firewalls, password protection and other access and authentication controls. We use SSL technology to encrypt data during transmission through public internet, and we also employ application-layer security features to further protect Personal Data.
        <br/>
        <h4>DATA RETENTION POLICY, MANAGING YOUR INFORMATION</h4>
        We will retain User Provided Information as defined in the sections above. Where not defined above, User Provided Information shall be retained for as long as you use the Application and for a reasonable time thereafter. If youâ€™d like us to delete User Provided Data that you have provided via the Application, please contact us at privacy@MyFSConnect.com and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly, and we may be required to retain certain information by law.
        Automatically Collected Information is retained for various time periods, up to six months, depending on customer enterprise configuration and contractual agreements. If youâ€™d like us to delete Automatically Collected Information about you, please contact us at privacy@MyFSConnect.com.
        <br/>
        <h4>MINORS AND CHILDREN</h4>
        The Application is not directed at minors under the age of 18, and we do not knowingly collect data from minors without obtaining parental consent. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us at privacy@MyFSConnect.com. We will delete such information from our files within a reasonable time.
        <br/>
        UNLESS OTHERWISE INDICATED, INFORMATION CONTAINED IN THIS SITE IS
        COPYRIGHT © 2023 JL13 Concepts. ALL RIGHTS RESERVED.
            </p>
            </div>
        </div>
    )
}

export default PrivacySection;
