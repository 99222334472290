import React from 'react'
import SectionTitle from '../../components/SectionTitles/SectionTitle'
import PartnerForm from '../../components/ProjectForm/PartnerForm'

const PartnerFormContainer = () => {
  return (
    <div className="contact-form-section section section-padding-t90-b100 bg-primary-blue">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-2 col-lg-8">
                        <SectionTitle
                            headingOption="fz-32"
                            title="Become a Partner"
                            subTitle="Fill this form to become a partner with MyFSConnect"
                        />
                        <PartnerForm/>
                        {/* <ContactFrom /> */}
                    </div>
                </div>
            </div>
        </div>
  )
}

export default PartnerFormContainer