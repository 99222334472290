import React from 'react'
import SEO from '../components/SEO'
import Header from '../partials/header/Header'
import Breadcrumb from '../container/Breadcrumb/Breadcrumb'
import Footer from '../container/Footer/Footer'
import ScrollToTop from '../components/ScrollToTop'
import PartnerFormContainer from '../container/ContactFromContainer/PartnerFormContainer'

const BecomePartner = () => {
  return (
    <React.Fragment>
            <SEO title="MyFSConnect || Become a Partner" />
            <Header />
            <Breadcrumb
                image="images/bg/breadcrumb-bg-five.jpg"
                title="Become a Partner"
                content="Home"
                contentTwo="Become a Partner"
            />
            <PartnerFormContainer />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
  )
}

export default BecomePartner