import React from 'react'

const LicenseSection = () => {
  return (
    <div className="google-map-area section text-center section-padding-bottom">
            <div className="container">
            <p className='privacy-text'>
            <h2>LICENSING AGREEMENT</h2>
            Unless otherwise indicated, information contained in this site is Copyright © 2023 JL13 Concepts Inc. (All Rights Reserved).
            This Software License Agreement (&quot;Agreement&quot;) is made between JL13 Concepts Inc. (&quot;Licensor&quot;) and the end user (&quot;Licensee&quot;). This Agreement governs the use of software and related materials provided by the Licensor.
        <br/>
        <h4>Grant of License</h4>
        Licensor grants Licensee a non-exclusive, non-transferable license to use the software and related materials (collectively, &quot;Software&quot;) provided by Licensor, subject to the terms of this Agreement.
        <br/>
        <h4>Ownership and Intellectual Property</h4>
        All intellectual property rights in the Software remain with Licensor. The Licensee acknowledges that the Software is licensed, not sold, and that no ownership rights are transferred under this Agreement.
        <br/>
        <h4>Permitted Uses and Restrictions</h4>
        Licensee may use the Software for lawful, personal, and internal business purposes. Licensee shall not, however, redistribute, sell, decompile, reverse engineer, disassemble, or otherwise reduce the Software to a human-perceivable form, except as may be allowed by applicable law.
        <br/>
        <h4>User Content and Contributions</h4>
        Any content or material Licensee provides in connection with the use of the Software shall be deemed non-confidential. Licensee grants Licensor a royalty-free, perpetual license to use, reproduce, modify, and distribute such content.
        <br/>
        <h4>Exclusion of Third-Party Rights</h4>
        Licensee acknowledges that any third-party software, content, or services that may be accessible through the Software are not covered by this Agreement and are subject to their respective terms and conditions.
        <br/>
        <h4>Amendments and Software Updates</h4>
        Licensor reserves the right to amend this Agreement and update the Software at its discretion. Continued use of the Software following such changes constitutes acceptance of the new terms and/or updates.
        <br/>
        <h4>Legal Compliance</h4>
        Licensee agrees to use the Software in compliance with all applicable laws and regulations, including but not limited to data protection and export control laws.
        <br/>
        <h4>Warranty Disclaimers</h4>
        The Software is provided on an &quot;as is&quot; basis without warranties of any kind, either express or implied. Licensor expressly disclaims all warranties, including implied warranties of merchantability or fitness for a particular purpose.
        <br/>
        <h4>Limitation of Liability</h4>
        Licensor shall not be liable for any indirect, special, incidental, or consequential damages arising out of or in connection with the use or performance of the Software.
        <br/>
        <h4>Use by Government Entities</h4>
        If Licensee is a government entity, the Software is provided as &quot;Commercial Computer Software&quot; and &quot;Commercial Computer Software Documentation,&quot; subject to the terms of this Agreement.
        <br/>
        <h4>Export Controls</h4>
        Licensee agrees not to export or re-export the Software in violation of any applicable laws and regulations of the United States or other jurisdictions.
        <br/>
        <h4>Governing Law</h4>
        This Agreement is governed by and construed in accordance with the laws of Canada, without giving effect to any principles of conflicts of law.
        <br/>
        <h4>Complete Agreement</h4>
        This Agreement constitutes the complete and exclusive statement of the agreement between the Parties regarding the Software and supersedes all prior agreements, proposals, and communications, written or oral, related to its subject matter.
By installing, copying, or otherwise using the Software, Licensee agrees to be bound by the terms of this Agreement.
        <br/>
        <h4>DATA RETENTION POLICY, MANAGING YOUR INFORMATION</h4>
        We will retain User Provided Information as defined in the sections above. Where not defined above, User Provided Information shall be retained for as long as you use the Application and for a reasonable time thereafter. If youâ€™d like us to delete User Provided Data that you have provided via the Application, please contact us at privacy@MyFSConnect.com and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly, and we may be required to retain certain information by law.
        Automatically Collected Information is retained for various time periods, up to six months, depending on customer enterprise configuration and contractual agreements. If youâ€™d like us to delete Automatically Collected Information about you, please contact us at privacy@MyFSConnect.com.
        <br/>
            </p>
            </div>
        </div>
  )
}

export default LicenseSection