import React from 'react'
import {NavLink} from 'react-router-dom';

const NavBar = () => {
    return (
        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">Home</span></NavLink>
                </li>
                <li>
                    <a href='/#feature'><span className="menu-text">Features</span></a>
                </li>
                <li>
                <a href='/#why-choose-us'><span className="menu-text"></span><span className="menu-text">Why Choose Us</span></a>
                </li>
                <li>
                <NavLink to={process.env.PUBLIC_URL + "/use-cases"}><span className="menu-text">Use Cases</span></NavLink>
                </li>
                {/* <li>
                <NavLink to={process.env.PUBLIC_URL + "/recommended-devices"}><span className="menu-text">Recommended Devices</span></NavLink>
                </li> */}
                <li>
                <a href='/#faq'><span className="menu-text">FAQs</span></a>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">Contact Us</span></NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar
