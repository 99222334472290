import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ContactInformation from '../container/ContactInformation/ContactInformation';
import GoogleMap from '../container/Map/GoogleMap';
import ContactFromContainer from '../container/ContactFromContainer/ContactFromContainer';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import ContactInformationTwo from '../container/ContactInformation/ContactInformationTwo.js';

const BookDemo = () => {
    return (
        <React.Fragment>
            <SEO title="MyFSConnect || Book Demo" />
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg-five.jpg"
                title="Book a Free Trial"
                content="Home"
                contentTwo="Book a Free Trial"
            />
            {/* <ContactInformationTwo/> */}
            <ContactFromContainer />
            {/* <ContactInformation />
             */}
            {/* <GoogleMap /> */}
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default BookDemo;

